import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import useResizeObserver from 'use-resize-observer'
import Project from 'components/Project'
import { useCoverImageStyle } from 'components/Project/hooks'
import CloseIcon from 'components/icons/CloseIcon'
import { Contract, MintSource, useCollectionResponse } from 'utils/api'
import classNames from 'utils/classnames'
import { background } from 'utils/theme'
import { useOpenConnectModal } from 'utils/web3/walletconnect'

type ModalProps = Pick<
  ComponentProps<typeof Dialog.Root>,
  'open' | 'onOpenChange' | 'defaultOpen'
> & {
  trigger?: ReactNode
  onOpen?: () => void
  onClose?: () => void
  contract?: Contract
  source: MintSource
}

export const ProjectModal = ({
  open,
  onOpenChange,
  onOpen,
  onClose,
  defaultOpen,
  trigger,
  contract,
  source,
}: ModalProps) => {
  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange?.(open)
      if (open) {
        onOpen?.()
      } else {
        onClose?.()
      }
    },
    [onOpenChange, onOpen, onClose],
  )

  // we want this data when it's available but don't want to fetch it here
  const collectionResponse = useCollectionResponse(contract, undefined, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: false,
    revalidateIfStale: false,
  })

  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useResizeObserver()

  const coverImageStyle = useCoverImageStyle(
    collectionResponse?.collection.headerImageUrl,
    collectionResponse?.collection.headerImageWidth,
    collectionResponse?.collection.headerImageHeight,
    contentWidth,
    contentHeight,
  )

  // our Radix Dialog component conflicts with the ConnectKit modal component since it listens
  // to any click outside of the modal, including the ConnectKit modal.
  //
  // this ignores any of those events if the ConnectKit modal is open.
  // the `open` state changes to false sooner than we can check, so we make a ref that changes one tick later
  const { open: connectModalIsOpen } = useOpenConnectModal()
  const connectModalIsOpenRef = useRef(connectModalIsOpen)
  useEffect(() => {
    setTimeout(() => {
      connectModalIsOpenRef.current = connectModalIsOpen
    }, 0)
  }, [connectModalIsOpen])

  return (
    <Dialog.Root
      open={open}
      onOpenChange={handleOpenChange}
      defaultOpen={defaultOpen}
      modal
    >
      {trigger != null ? (
        <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      ) : null}
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(
            'project-modal-overlay', // used for fade animation
            'fixed inset-0 bg-black/70 dark:bg-white/10',
            'overflow-y-auto',
            'flex items-center justify-center',
            // this padding is applied here so it is clickable to dismiss
            // if it was part of the modal content it wouldn't dismiss
            'md:pt-24',
          )}
        >
          <Dialog.Content
            className={classNames(
              'project-modal-content',
              'w-full h-full max-w-screen-xl',
            )}
            onEscapeKeyDown={(event) => {
              if (connectModalIsOpenRef.current) {
                event.preventDefault()
              }
            }}
            onPointerDownOutside={(event) => {
              if (connectModalIsOpenRef.current) {
                event.preventDefault()
              }
            }}
            onInteractOutside={(event) => {
              if (connectModalIsOpenRef.current) {
                event.preventDefault()
              }
            }}
          >
            {/* wrapper div to make the background full height when there is scrollable content */}
            <div
              ref={contentRef}
              className={classNames(
                background,
                'px-3 md:p-10 py-3 md:rounded-3xl',
              )}
              style={coverImageStyle}
            >
              {contract !== undefined && (
                <Project
                  variant="modal"
                  contract={contract}
                  className="w-full h-full"
                  source={source}
                  closeButton={
                    <Dialog.Close
                      aria-label="Close"
                      title="Close"
                      className="self-end opacity-50 hover:opacity-100 w-9 h-9 flex items-center justify-center"
                    >
                      <CloseIcon className="w-5 h-5" />
                    </Dialog.Close>
                  }
                />
              )}
            </div>

            {/* hack to add bottom padding - please replace with a better solution */}
            <div className="md:h-24 pointer-events-none" />
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
