import { useMemo } from 'react'
import { Profile } from 'utils/api'
import Owner, { ownerImageSize } from './Owner'

type Props = {
  profiles: Profile[]
}

export default function AvatarStack({ profiles }: Props) {
  const avatarWidth = useMemo(() => {
    if (profiles === undefined) {
      return 0
    }

    return ownerImageSize / 2 + profiles.length * ownerImageSize * 0.5
  }, [profiles])

  const reversed = useMemo(() => [...profiles].reverse(), [profiles])

  return (
    <div
      className="flex items-center relative mr-2"
      style={{
        height: ownerImageSize,
        width: avatarWidth,
      }}
    >
      {reversed.map((profile, index) => (
        <Owner
          index={index}
          count={reversed.length}
          profile={profile}
          key={profile.id}
        />
      ))}
    </div>
  )
}
