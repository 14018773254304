import React, { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useOpenAuthFlow } from 'providers/Auth'
import {
  FeedId,
  FeedTimeRange,
  allTimeRanges,
  defaultFeedRange,
  defaultFeedTabs,
  feedInfo,
  isTimeRange,
  withParams,
} from 'utils/api'
import classnames from 'utils/classnames'
import { textPrimary, textPrimaryOnHover, textSecondary } from 'utils/theme'
import { useAccount } from 'utils/useAccount'
import { useOpenConnectModal } from 'utils/web3/walletconnect'
import { ChainDropdownSelect } from './DropdownChain'
import OptionSelector, { OptionSelectorOption } from './OptionSelector'

const timeRangeOptions: OptionSelectorOption<FeedTimeRange>[] =
  allTimeRanges.map((timeRange) => ({ key: timeRange, label: timeRange }))

type Props = {
  feedId: FeedId
}

function FeedNav({ feedId }: Props) {
  const { push, query } = useRouter()
  const { openConnectModal } = useOpenConnectModal()
  const openAuthFlow = useOpenAuthFlow()

  const { isLoggedIn, isWalletConnected } = useAccount()

  const range = isTimeRange(query.range) ? query.range : defaultFeedRange

  const onSelectTimeRange = useCallback(
    (timeRange: FeedTimeRange) => {
      const range = timeRange !== defaultFeedRange ? timeRange : undefined
      push(
        withParams(`/feed/${feedId}`, { chain: query.chain, range }),
        undefined,
        { shallow: true },
      )
    },
    [feedId, push, query],
  )

  const feedOptions = useMemo(() => {
    const range = query.range !== defaultFeedRange ? query.range : undefined

    return defaultFeedTabs.map((key) => ({
      key,
      label: feedInfo[key].name,
      href:
        feedInfo[key].authRequired && !isLoggedIn
          ? undefined
          : withParams(`/feed/${key}`, {
              chain: query.chain,
              range: feedInfo[key].supportsTimeRange ? range : undefined,
            }),
    }))
  }, [isLoggedIn, query.chain, query.range])

  const onSelectFeed = useCallback(
    (feed: FeedId) => {
      const requiresAuth = feedInfo[feed].authRequired
      if (requiresAuth && !isLoggedIn) {
        if (!isWalletConnected) {
          // no wallet connected
          openConnectModal?.()
        } else {
          // wallet connected, not authed
          openAuthFlow()
        }
        return
      }
    },
    [isLoggedIn, isWalletConnected, openAuthFlow, openConnectModal],
  )

  return (
    <div className="flex flex-col md:flex-row gap-y-4 justify-between">
      {/* the intention with this CSS is to have it scroll horizontally but keep the padding */}
      {/* as if there was no wrapper div or additional classes. feel free to change this */}
      <div className="overflow-x-auto -mx-3 px-3 flex gap-2">
        <ChainDropdownSelect />
        <OptionSelector
          options={feedOptions}
          selected={feedId}
          onSelectOption={onSelectFeed}
          className="w-fit"
        />
      </div>
      {(feedId === 'trending' || feedId === 'free') && (
        <>
          <div className="hidden md:flex flex-row gap-x-3 items-center justify-center">
            {allTimeRanges.map((timeRange) => (
              <span
                key={timeRange}
                role="button"
                onClick={() => onSelectTimeRange(timeRange)}
                className={classnames(
                  range === timeRange
                    ? [textPrimary, 'underline']
                    : [textSecondary, textPrimaryOnHover],
                  'text-md font-bold',
                  'transition-colors duration-100',
                )}
              >
                {timeRange}
              </span>
            ))}
          </div>
          <OptionSelector
            options={timeRangeOptions}
            selected={range}
            onSelectOption={onSelectTimeRange}
            className="flex md:hidden self-start"
          />
        </>
      )}
    </div>
  )
}

export default React.memo(FeedNav)
