import React from 'react'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import Link from 'next/link'
import { ChainIcon } from 'components/ChainIcon'
import PendingTransactionsChip from 'components/PendingTransactionsChip'
import ProjectSubtitleText from 'components/ProjectSubtitleText'
import { ProjectWarningLabelBadge } from 'components/ProjectWarningLabel'
import MintRewards from 'components/icons/Roadmap/MintRewards'
import {
  Collection,
  PendingTransactionsResponse,
  contractPendingTransactionsEndpoint,
  useAPIResponse,
} from 'utils/api'
import { getChainByContract, getContractPath } from 'utils/chains'
import classnames from 'utils/classnames'
import { specialDestination } from 'utils/special-contracts'
import { textSecondary } from 'utils/theme'
import { formatTimestamp, isValidIsoTimestamp } from 'utils/time'

type Props = {
  collection: Collection
  listIndex?: number
  shouldActivelyFetch: boolean
  size?: 'small' | 'large'
}

function ProjectFeedRowHeading({
  collection,
  listIndex,
  shouldActivelyFetch,
  size = 'large',
}: Props) {
  const chain = getChainByContract(collection.contract)
  const { data: pendingTxData } = useAPIResponse<PendingTransactionsResponse>(
    contractPendingTransactionsEndpoint({
      contract: collection.contract,
    }),
    undefined,
    {
      refreshInterval: shouldActivelyFetch ? 5000 : undefined,
      revalidateIfStale: shouldActivelyFetch,
      revalidateOnFocus: shouldActivelyFetch,
      revalidateOnReconnect: shouldActivelyFetch,
      revalidateOnMount: shouldActivelyFetch,
    },
  )

  return (
    <div
      className={classnames('flex flex-col', {
        'gap-1': size === 'large',
      })}
    >
      <div
        className={classnames('flex gap-x-2 font-bold', {
          'text-2xl md:text-3xl items-start': size === 'large',
          'items-center': size === 'small',
        })}
      >
        {collection.flags !== undefined && (
          <ProjectWarningLabelBadge
            flags={collection.flags}
            buttonClassName="flex items-center"
            className={size === 'small' ? 'pt-0 md:pt-0' : undefined}
          />
        )}

        <div className="flex items-center gap-x-2 md:gap-x-4 font-bold">
          <span
            className={classnames('[overflow-wrap:anywhere]', {
              'line-clamp-3': size === 'large',
              'line-clamp-2': size === 'small',
            })}
          >
            {listIndex !== undefined && (
              <span
                className={classnames(
                  'md:hidden text-sm font-display pr-3 relative',
                  {
                    '-top-0.5': size === 'large',
                  },
                )}
              >
                {listIndex + 1}
              </span>
            )}

            <Link
              href={
                specialDestination(collection.contract) ??
                getContractPath(collection.contract)
              }
              className="hover:underline inline mr-3 normal-case"
            >
              {collection.name}
            </Link>
            <span
              className={classnames(
                textSecondary,
                'text-base font-normal',
                'hidden md:inline mr-3 relative',
                {
                  '-top-0.5': size === 'large',
                },
              )}
            >
              {isValidIsoTimestamp(collection.firstEvent) &&
                formatTimestamp(new Date(collection.firstEvent))}
            </span>
            {chain !== undefined && (
              <ChainIcon
                chainId={chain.id}
                className="h-5 w-5 inline relative -top-0.5"
                variant="mono"
              />
            )}
            {collection.isFunContract && (
              <MintRewards className="w-6 h-6 inline relative -top-0.5 text-neutral-700 ml-1" />
            )}
          </span>
        </div>
      </div>

      <LayoutGroup>
        <div
          className={classnames(
            'flex items-center gap-x-3 md:gap-x-5',
            'text-sm',
            {
              'md:text-xl': size === 'large',
            },
          )}
        >
          <AnimatePresence>
            {pendingTxData !== undefined && pendingTxData.total > 0 && (
              <motion.div
                layout="position"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                  duration: 0.2,
                  layout: { duration: 0.2 },
                }}
              >
                <PendingTransactionsChip
                  total={pendingTxData.total}
                  wallets={pendingTxData.wallets}
                  size={size}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            layout="position"
            transition={{
              layout: { duration: 0.2 },
            }}
          >
            <ProjectSubtitleText
              collection={collection}
              mintsLastHour={pendingTxData?.lastHour}
            />
          </motion.div>
        </div>
      </LayoutGroup>
    </div>
  )
}

export default React.memo(ProjectFeedRowHeading)
