import React from 'react'
import StarFilled from 'components/icons/StarFilled'
import classnames from 'utils/classnames'

type Props = { multiple?: boolean; className?: string }

const FeaturedProjectChip = ({ multiple, className }: Props) => {
  return (
    <div
      className={classnames(
        'inline-flex items-center gap-x-2',
        'rounded-xl bg-neutral-300 dark:bg-neutral-700',
        'px-3 h-9 mb-2 md:mb-8',
        className,
      )}
    >
      <StarFilled className="w-4 h-4" />
      Featured project{multiple === true && 's'}
    </div>
  )
}

export default React.memo(FeaturedProjectChip)
