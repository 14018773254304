import React, { useMemo } from 'react'
import { LayoutGroup, motion } from 'framer-motion'
import FeaturedProjectView from 'components/FeaturedProject'
import FeedNav from 'components/FeedNav'
import InfoBanner from 'components/InfoBanner'
import { ProjectModal } from 'components/Project/ProjectModal'
import { useProjectModalState } from 'components/Project/ProjectModal/hooks'
import ProjectFeedRow from 'components/ProjectFeedRow'
import {
  CollectionFeedResponse,
  Contract,
  FeaturedProject,
  FeedId,
  SampleNFTsResponse,
  feedInfo,
} from 'utils/api'
import { useHasDismissedEmailSignup } from 'utils/checkpoints'
import { useAccount } from 'utils/useAccount'
import EmailView from './FeedPageEmail'

type Props = {
  feedId: FeedId
  initialSampleNfts?: Record<Contract, SampleNFTsResponse>
  feedData?: CollectionFeedResponse
  featuredProjects?: FeaturedProject[]
}

function FeedPageView({
  feedId,
  initialSampleNfts,
  feedData,
  featuredProjects,
}: Props) {
  const { projectModalOpen, selectedContract, handleExpand, handleModalClose } =
    useProjectModalState(`/feed/${feedId}`)
  const { account } = useAccount()

  const showNumbers = feedInfo[feedId].showNumbersInList

  const [isDismissed, isDismissedSet] = useHasDismissedEmailSignup()
  const isVerified = account?.emailVerified === true
  const signUpDismissed = isDismissed ?? false
  const showEmailSignup =
    feedId === 'allowlist' && !isVerified && !signUpDismissed

  // top 5 collections get their own rows
  const feedRows = useMemo(() => {
    if (feedData?.collections === undefined) return undefined

    return feedData.collections.slice(0, 5)
  }, [feedData?.collections])

  // everything else goes in a table like view
  const feedTable = useMemo(() => {
    if (
      feedData?.collections === undefined ||
      feedData.collections.length <= 5
    ) {
      return undefined
    }

    return feedData.collections.slice(5)
  }, [feedData?.collections])

  return (
    <div className="flex flex-col gap-y-7">
      {featuredProjects !== undefined && (
        <>
          <FeaturedProjectView
            featuredProjects={featuredProjects}
            onExpand={(featuredProject: FeaturedProject) =>
              handleExpand(featuredProject.collection.contract)
            }
          />
          <div className="border-b border-neutral-400 dark:border-neutral-700" />
        </>
      )}

      <FeedNav feedId={feedId} />

      <ProjectModal
        open={projectModalOpen}
        contract={selectedContract}
        onClose={handleModalClose}
        source={`feed:${feedId}`}
      />

      {feedId === 'free' && (
        <InfoBanner>
          <span>
            Many free mints have platform fees. The free tab includes mints with
            minimal <span className="uppercase">ETH</span> costs.
          </span>
        </InfoBanner>
      )}

      {showEmailSignup && <EmailView isDismissedSet={isDismissedSet} />}

      {feedData === undefined && (
        <div className="font-semibold min-h-[42rem]">Loading...</div>
      )}

      {feedRows !== undefined && feedRows.length > 0 && (
        <LayoutGroup>
          <div className="flex flex-col gap-y-7 md:gap-y-10">
            {feedRows.map((col, i) => (
              <motion.div
                key={col.contract}
                layout="position"
                transition={{
                  layout: { duration: 0.2 },
                }}
              >
                <ProjectFeedRow
                  collection={col}
                  index={showNumbers ? i : undefined}
                  onExpand={() => handleExpand(col.contract)}
                  initialSampleNfts={initialSampleNfts?.[col.contract]}
                />
              </motion.div>
            ))}
          </div>
        </LayoutGroup>
      )}
      {feedTable !== undefined && feedTable.length > 0 && (
        <div className="flex flex-col w-full">
          {feedTable.map((col, i, arr) => (
            <ProjectFeedRow
              key={col.contract}
              collection={col}
              onExpand={() => handleExpand(col.contract)}
              index={showNumbers ? 5 + i : undefined}
              isLast={i === arr.length - 1}
              layout="table-row"
            />
          ))}
        </div>
      )}
      {feedData && feedData.collections.length === 0 && (
        <div className="flex items-center max-w-lg text-center text-xl self-center min-h-[277px]">
          <div className="w-full flex flex-col items-center justify-center">
            {feedId === 'following' ? (
              <span>
                {
                  'None of your follows have minted an NFT project recently. Try following some more wallets!'
                }
              </span>
            ) : (
              <>
                <span>No projects to display right now.</span>
                <span>Check back later...</span>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(FeedPageView)
