import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { withErrorBoundary } from '@sentry/nextjs'
import Link from 'next/link'
import { Button, Variant } from 'components/Button'
import NFTCarousel from 'components/NFTCarousel'
import Media from 'components/NFTView/Media'
import { collectionIsMintedOut } from 'components/Project/utils'
import SocialProof from 'components/SocialProof'
import ChevronRight from 'components/icons/ChevronRight'
import {
  Collection,
  NFTSample,
  SampleNFTsResponse,
  contractEndpoint,
  contractMintTimesEndpoint,
  contractSampleNFTsEndpoint,
  contractTransactionsEndpoint,
  useAPIResponse,
  useCollectionResponse,
} from 'utils/api'
import { getContractPath } from 'utils/chains'
import classnames from 'utils/classnames'
import { fundropButtonColorClasses } from 'utils/fundrop'
import { isSpecialContract, specialDestination } from 'utils/special-contracts'
import { mutateRead } from 'utils/swr'
import Heading from './Heading'

const carouselClass = 'mb-2 mt-4'

type Props = {
  collection: Collection
  index?: number
  onExpand: () => void
  initialSampleNfts?: SampleNFTsResponse
  layout?: 'carousel-row' | 'table-row'
  isLast?: boolean
}

type RowProps = {
  index?: number
  shouldActivelyFetch: boolean
  collection: Collection
  sampleNfts: SampleNFTsResponse | undefined
  mintButtonVariant: Variant
  isAllowlistMintableForWallet: boolean
  isSpecial: boolean
  mintButtonCopy: string
  isMintedOut: boolean
  handleMintHover: () => void
  onExpand: () => void
  isLast?: boolean
}

const CarouselRowComponent = (
  {
    index,
    shouldActivelyFetch,
    collection,
    sampleNfts,
    mintButtonVariant,
    isAllowlistMintableForWallet,
    isSpecial,
    mintButtonCopy,
    isMintedOut,
    handleMintHover,
    onExpand,
  }: RowProps,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <div ref={ref} className="flex items-start gap-6 justify-center">
    {index !== undefined && (
      <div className="hidden md:flex items-center justify-center text-base font-display h-8 w-10 mt-1">
        <span>{index + 1}</span>
      </div>
    )}

    <div className="flex flex-col items-start w-full">
      <Heading
        listIndex={index}
        shouldActivelyFetch={shouldActivelyFetch}
        collection={collection}
      />

      <NFTCarousel
        className={carouselClass}
        nftsResponse={sampleNfts}
        rowIndex={index}
        size="mobile"
      />
      <NFTCarousel
        className={carouselClass}
        nftsResponse={sampleNfts}
        rowIndex={index}
        size="tablet"
      />
      <NFTCarousel
        className={carouselClass}
        nftsResponse={sampleNfts}
        rowIndex={index}
        size="desktop"
      />

      <div
        className={classnames(
          'flex justify-start items-start md:items-center',
          'flex-col-reverse md:flex-row',
          'w-full',
          'gap-4 md:gap-0',
          'mt-3 md:mt-4',
        )}
      >
        <Button
          variant={mintButtonVariant}
          className={classnames(
            'mr-6 w-60',
            isAllowlistMintableForWallet &&
              'al-btn transition-colors duration-300',
            isSpecial && fundropButtonColorClasses,
          )}
          label={mintButtonCopy}
          icon={isMintedOut ? undefined : <ChevronRight className="w-5 h-5" />}
          iconPosition="end"
          onMouseOver={handleMintHover}
          onClick={onExpand}
        />

        <SocialProof
          contract={collection.contract}
          isFullPage={false}
          shouldActivelyFetch={shouldActivelyFetch}
        />
      </div>
    </div>
  </div>
)

const CarouselRow = forwardRef(CarouselRowComponent)

const TableRowComponent = (
  {
    index,
    shouldActivelyFetch,
    collection,
    sampleNfts,
    mintButtonVariant,
    isAllowlistMintableForWallet,
    isSpecial,
    mintButtonCopy,
    isMintedOut,
    handleMintHover,
    onExpand,
    isLast,
  }: RowProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const oneSampleNFT: NFTSample | undefined = useMemo(
    () =>
      sampleNfts !== undefined && sampleNfts.first !== null
        ? sampleNfts.first
        : undefined,
    [sampleNfts],
  )

  return (
    <div
      ref={ref}
      className={classnames(
        'flex items-center gap-6 justify-center py-4 w-full',
        {
          ' border-b border-neutral-200 dark:border-neutral-800':
            isLast !== true,
        },
      )}
    >
      {index !== undefined && (
        <div className="hidden md:flex items-center justify-center text-base font-display h-8 w-10">
          <span>{index + 1}</span>
        </div>
      )}

      <div className="flex flex-1 space-between w-full">
        {oneSampleNFT !== undefined && (
          <Link
            href={
              specialDestination(collection.contract) ??
              getContractPath(collection.contract)
            }
            className="w-[60px] h-[60px] mr-4"
          >
            <Media
              nft={oneSampleNFT}
              width={60}
              height={60}
              className="rounded-md"
            />
          </Link>
        )}
        <div className="flex-1 flex items-center">
          <Heading
            listIndex={index}
            shouldActivelyFetch={shouldActivelyFetch}
            collection={collection}
            size="small"
          />
        </div>
        <div className="hidden md:flex items-center">
          <Button
            variant={mintButtonVariant}
            className={classnames(
              'w-36',
              isAllowlistMintableForWallet &&
                'al-btn transition-colors duration-300',
              isSpecial && fundropButtonColorClasses,
            )}
            label={mintButtonCopy}
            icon={
              isMintedOut ? undefined : (
                <ChevronRight className="w-[0.75em] h-[0.75em] -mb-0.5" />
              )
            }
            iconPosition="end"
            onMouseOver={handleMintHover}
            onClick={onExpand}
          />
        </div>
      </div>
    </div>
  )
}

const TableRow = forwardRef(TableRowComponent)

function ProjectFeedRow({
  collection: initialCollection,
  index,
  onExpand,
  initialSampleNfts,
  layout = 'carousel-row',
  isLast = false,
}: Props) {
  const { ref, inView: shouldActivelyFetch } = useInView({
    threshold: 0,
    rootMargin: '300px',
  })

  const contract = initialCollection.contract

  const collectionResponse = useCollectionResponse(
    contract,
    { collection: initialCollection, profiles: [] },
    {
      refreshInterval: shouldActivelyFetch ? 1000 * 20 : undefined,
      revalidateIfStale: shouldActivelyFetch,
      revalidateOnFocus: shouldActivelyFetch,
      revalidateOnReconnect: shouldActivelyFetch,
      revalidateOnMount: false,
    },
  )

  const collection = collectionResponse?.collection ?? initialCollection

  const isMintedOut = useMemo(
    () => collectionIsMintedOut(collection) ?? false,
    [collection],
  )

  const {
    mintButtonCopy,
    mintButtonVariant,
    isAllowlistMintableForWallet,
  }: {
    mintButtonCopy: string
    mintButtonVariant: Variant
    isAllowlistMintableForWallet: boolean
  } = useMemo(() => {
    const isAllowlistMintableForWallet =
      collection?.allowlist !== undefined
        ? collection.allowlist === 'addressOnKnownAllowlist'
        : false

    const mintButtonCopy = isMintedOut
      ? 'Minted out'
      : isAllowlistMintableForWallet
      ? 'Mint via Allowlist'
      : 'Mint now'

    const mintButtonVariant: Variant = isMintedOut ? 'outline' : 'primary'

    return { mintButtonCopy, mintButtonVariant, isAllowlistMintableForWallet }
  }, [collection?.allowlist, isMintedOut])

  const shouldActivelyFetchSampleNFTs =
    shouldActivelyFetch && layout === 'carousel-row'
  const { data: sampleNfts } = useAPIResponse<SampleNFTsResponse>(
    contractSampleNFTsEndpoint({ contract }),
    initialSampleNfts,
    {
      refreshInterval: shouldActivelyFetchSampleNFTs ? 1000 * 15 : undefined,
      revalidateIfStale: shouldActivelyFetchSampleNFTs,
      revalidateOnFocus: shouldActivelyFetchSampleNFTs,
      revalidateOnReconnect: shouldActivelyFetchSampleNFTs,
    },
  )

  const [originalUrl, originalUrlSet] = useState<string | undefined>(undefined)

  useEffect(() => {
    // save the original url so we can restore it when the modal is closed
    if (originalUrl === undefined) {
      originalUrlSet(window.location.href)
    }
  }, [originalUrl])

  const [hasPrefetchedForHover, hasPrefetchedForHoverSet] = useState(false)

  const isSpecial = useMemo(() => isSpecialContract(contract), [contract])

  const handleMintHover = useCallback(() => {
    if (hasPrefetchedForHover) {
      return
    }

    if (isSpecial) {
      return
    }

    mutateRead(contractEndpoint({ contract }))
    mutateRead(contractTransactionsEndpoint({ contract }))
    mutateRead(contractMintTimesEndpoint({ contract }))

    hasPrefetchedForHoverSet(true)
  }, [contract, hasPrefetchedForHover, isSpecial])

  const rowProps = {
    index,
    shouldActivelyFetch,
    collection,
    sampleNfts,
    mintButtonVariant,
    isAllowlistMintableForWallet,
    isSpecial,
    mintButtonCopy,
    isMintedOut,
    handleMintHover,
    onExpand,
    isLast,
  }

  if (layout === 'table-row') {
    return <TableRow ref={ref} {...rowProps} />
  }

  return <CarouselRow ref={ref} {...rowProps} />
}

export default React.memo(withErrorBoundary(ProjectFeedRow, {}))
