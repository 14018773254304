import { ReactNode } from 'react'
import TranslucentMaterial from './TranslucentMaterial'
import InfoIcon from './icons/InfoIcon'

type Props = {
  children: ReactNode
}

export default function InfoBanner({ children }: Props) {
  return (
    <TranslucentMaterial className="flex flex-row items-center justify-center gap-x-3 self-start py-3">
      <InfoIcon className="shrink-0" />
      {children}
    </TranslucentMaterial>
  )
}
