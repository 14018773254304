import EmailSignup from 'components/EmailSignup'
import CloseIcon from 'components/icons/CloseIcon'
import classNames from 'utils/classnames'
import { useHasMounted } from 'utils/react-helpers'

type FeedPageEmailProps = {
  isDismissedSet: (isDismissed: boolean) => void
}

export default function FeedPageEmail({ isDismissedSet }: FeedPageEmailProps) {
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <div className="w-full text-center">
      <div className="w-full flex flex-col items-center justify-center">
        <div
          className={classNames(
            'w-full flex items-center justify-center text-center text-sm md:text-base relative',
            'border border-neutral-400 dark:border-neutral-700 rounded-xl p-12 min-h-[277px]',
          )}
        >
          <div className="absolute cursor-pointer m-3 md:m-8 w-[14px] h-[14px] top-0 right-0">
            <CloseIcon onClick={() => isDismissedSet(true)} />
          </div>

          <div className="flex flex-col items-center">
            <div className="flex flex-col max-w-2xl gap-y-8">
              <EmailSignup />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
