import React, { useMemo } from 'react'
import { useBreakpoints } from 'react-match-breakpoints'
import Link from 'next/link'
import AddressListModal from 'components/AddressListModal'
import {
  CollectionSocialProofResponse,
  Contract,
  contractSocialProofEndpoint,
  useAPIResponse,
} from 'utils/api'
import classNames from 'utils/classnames'
import { s } from 'utils/helpers'
import { useAccount } from 'utils/useAccount'
import AvatarStack from './AvatarStack'

type Props = {
  isFullPage: boolean
  contract: Contract
  shouldActivelyFetch?: boolean
}

function SocialProof({
  isFullPage,
  contract,
  shouldActivelyFetch = true,
}: Props) {
  const { address } = useAccount()
  const bp = useBreakpoints()

  const { data: collectionSocialProofResponse } =
    useAPIResponse<CollectionSocialProofResponse>(
      address !== undefined
        ? contractSocialProofEndpoint({ contract, address })
        : '',
      undefined,
      {
        skipFetching: address === undefined || !shouldActivelyFetch,
        revalidateIfStale: false,
      },
    )

  const { textNodes, avatars } = useMemo(() => {
    if (
      address === undefined ||
      collectionSocialProofResponse === undefined ||
      collectionSocialProofResponse.count === 0
    ) {
      return {}
    }

    const firstWallet = collectionSocialProofResponse.items[0]
    const secondWallet = collectionSocialProofResponse.items[1]

    const moreWalletsCount =
      collectionSocialProofResponse.count - (bp.md ? 2 : 1)
    const hasMoreWallets = moreWalletsCount > 0

    const nodes: Array<React.ReactNode> = ['Minted by']
    const visibleWallets = [firstWallet]

    nodes.push(
      <React.Fragment key="firstWallet">
        {' '}
        <Link href={`/profile/${firstWallet.profile.slug}`}>
          <span className="font-bold hover:underline">
            {firstWallet.profile.name}
          </span>
        </Link>
      </React.Fragment>,
    )

    if (secondWallet !== undefined && bp.md) {
      visibleWallets.push(secondWallet)

      nodes.push(
        <React.Fragment key="secondWallet">
          {`${hasMoreWallets ? ',' : ' and'} `}
          <Link href={`/profile/${secondWallet.profile.slug}`}>
            <span className="font-bold hover:underline">
              {secondWallet.profile.name}
            </span>
          </Link>
        </React.Fragment>,
      )
    }

    if (hasMoreWallets) {
      nodes.push(
        <React.Fragment key="moreWallets">
          {' and '}
          <AddressListModal
            title="Minters"
            trigger={
              <button className="font-bold hover:underline">
                {moreWalletsCount} other
                {s(moreWalletsCount)}
              </button>
            }
            profiles={collectionSocialProofResponse.items.map((wallet) => ({
              ...wallet.profile,
              isFollowing: true,
            }))}
          />
        </React.Fragment>,
      )
    }

    const avatars = visibleWallets.map((wallet) => wallet.profile)

    return { textNodes: nodes, avatars }
  }, [address, bp.md, collectionSocialProofResponse])

  if (textNodes === undefined) {
    return null
  }

  return (
    <div
      className={classNames(
        'flex flex-row items-center',
        isFullPage && textNodes !== undefined && 'h-12',
      )}
    >
      <AvatarStack profiles={avatars} />
      <div>{textNodes}</div>
    </div>
  )
}

export default React.memo(SocialProof)
