import { SVGAttributes } from 'react'

export default function ChevronDown(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.4 14.667c0 .736-.627 1.333-1.4 1.333-.773 0-1.4-.597-1.4-1.333v-1.334H9.48c-.928 0-1.68-.716-1.68-1.6v-1.066H6.4c-.773 0-1.4-.597-1.4-1.334C5 8.597 5.627 8 6.4 8c.773 0 1.4.597 1.4 1.333v1.334h1.12c.928 0 1.68.716 1.68 1.6v1.066h2.8v-1.066c0-.884.752-1.6 1.68-1.6h1.12V9.333c0-.736.627-1.333 1.4-1.333.773 0 1.4.597 1.4 1.333 0 .737-.627 1.334-1.4 1.334h-1.4v1.066c0 .884-.752 1.6-1.68 1.6H13.4v1.334z"
      ></path>
    </svg>
  )
}
