import classnames from 'utils/classnames'

const sharedTriggerClasses = 'px-4 text-sm md:text-base whitespace-nowrap'

export const variants = {
  primary: {
    trigger: classnames(
      sharedTriggerClasses,
      'bg-neutral-200 dark:bg-neutral-800 text-black dark:text-white',
    ),
  },
  outline: {
    trigger: classnames(sharedTriggerClasses, 'bg-white dark:bg-neutral-800'),
  },
}

export const defaultVariant = 'outline'
