import React from 'react'
import Avatar from 'components/Avatar'
import { Profile } from 'utils/api'

interface Props {
  profile: Profile
  index: number
  count: number
}

export const ownerImageSize = 20

function Owner({ profile, index, count }: Props) {
  const left = (count - index - 1) * ownerImageSize * 0.5

  return (
    <div
      style={{
        position: 'absolute',
        left,
      }}
    >
      <Avatar size={ownerImageSize} slug={profile.slug} />
    </div>
  )
}

export default React.memo(Owner)
