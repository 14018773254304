import React, { useCallback, useMemo } from 'react'
import Link from 'next/link'
import { Button } from 'components/Button'
import NFTView from 'components/NFTView'
import Media from 'components/NFTView/Media'
import { collectionIsMintedOut } from 'components/Project/utils'
import ProjectSubtitleText from 'components/ProjectSubtitleText'
import { PreparedTransactionButton } from 'components/TransactionButton'
import { sortTransactions } from 'components/TransactionList/utils'
import {
  ContractTransaction,
  FeaturedProject,
  MintSource,
  NFTSample,
} from 'utils/api'
import { getContractPath } from 'utils/chains'
import classnames from 'utils/classnames'
import { formatTokenAmount } from 'utils/web3/helpers'
import FeaturedProjectChip from './FeaturedProjectChip'
import FeaturedProjectDetails from './FeaturedProjectDetails'

type FeaturedProjectProps = {
  onExpand: (featuredProject: FeaturedProject) => void
  source?: MintSource
}

type Props = FeaturedProjectProps & {
  featuredProjects: FeaturedProject[]
}

function FeaturedProjectView(props: Props) {
  const { featuredProjects, ...restProps } = props

  if (featuredProjects.length > 1) {
    return (
      <FeaturedProjectMultiple
        featuredProjects={featuredProjects}
        {...restProps}
      />
    )
  }

  return (
    <FeaturedProjectSingle
      featuredProject={featuredProjects[0]}
      {...restProps}
    />
  )
}

type PropsSingle = FeaturedProjectProps & {
  featuredProject: FeaturedProject
}

function useFeaturedProject(featuredProject: FeaturedProject) {
  const { collection, sampleNFTs, profiles, transactions } = featuredProject

  const transaction: ContractTransaction | undefined = useMemo(() => {
    if (transactions === undefined) return undefined
    return transactions.sort(sortTransactions)[0]
  }, [transactions])

  const oneSampleNFT: NFTSample | undefined = useMemo(
    () => (sampleNFTs.length > 0 ? sampleNFTs[0] : undefined),
    [sampleNFTs],
  )
  const twoSampleNFTs = useMemo(() => sampleNFTs.slice(0, 2), [sampleNFTs])
  const threeSampleNFTs = useMemo(() => sampleNFTs.slice(0, 3), [sampleNFTs])

  const isMintedOut = useMemo(
    () => collectionIsMintedOut(collection) ?? false,
    [collection],
  )

  return {
    collection,
    sampleNFTs,
    transaction,
    profiles,
    oneSampleNFT,
    twoSampleNFTs,
    threeSampleNFTs,
    isMintedOut,
  }
}

function FeaturedProjectSingle({
  featuredProject,
  onExpand,
  source = 'featured',
}: PropsSingle) {
  const {
    collection,
    profiles,
    transaction,
    oneSampleNFT,
    twoSampleNFTs,
    threeSampleNFTs,
    isMintedOut,
  } = useFeaturedProject(featuredProject)

  return (
    <div className="featured-project flex flex-col-reverse md:flex-row justify-between gap-x-4 md:gap-y-4 py-2">
      {/* collection name, mint button */}
      <div className="flex flex-col items-center md:items-start">
        <div className="hidden md:block mb-8">
          <FeaturedProjectDetails collection={collection} />
        </div>
        <div className="-mt-3 md:mt-0 w-full md:w-auto">
          {isMintedOut ? (
            <Button
              variant="outline"
              label="Minted out"
              className="w-full md:w-60"
              onClick={() => onExpand(featuredProject)}
            />
          ) : (
            transaction !== undefined && (
              <PreparedTransactionButton
                collectionContract={collection.contract}
                transaction={transaction}
                collectionName={collection.name}
                collectionFlags={collection.flags}
                ignoreWarnings
                buttonSettings={collection.settings.mintButton}
                source={source}
                wrapperClassName="max-w-none"
              />
            )
          )}
        </div>
      </div>
      <div className="flex md:hidden w-full relative">
        {oneSampleNFT !== undefined && (
          <Media
            nft={oneSampleNFT}
            className="rounded-md fade-out-bottom"
            loading="eager"
          />
        )}
        <div className="absolute bottom-0 left-0 w-full py-2 px-3 mb-6">
          <FeaturedProjectDetails collection={collection} />
        </div>
      </div>

      {twoSampleNFTs.length > 0 && (
        <div className="hidden md:flex lg:hidden flex-row gap-x-4 items-start">
          {twoSampleNFTs.map((nft, i) => (
            <NFTView
              key={i}
              nft={nft}
              size={160}
              profiles={profiles}
              loading="eager"
            />
          ))}
        </div>
      )}
      {threeSampleNFTs.length > 0 && (
        <div className="hidden lg:flex flex-row gap-x-4 items-start">
          {threeSampleNFTs.map((nft, i) => (
            <NFTView
              key={i}
              nft={nft}
              size={170}
              profiles={profiles}
              loading="eager"
            />
          ))}
        </div>
      )}
    </div>
  )
}

function FeaturedProjectMultipleEntry({
  featuredProject,
  className,
  onExpand,
}: {
  featuredProject: FeaturedProject
  className?: string
  onExpand: (featuredProject: FeaturedProject) => void
}) {
  const { collection, transaction, oneSampleNFT, isMintedOut } =
    useFeaturedProject(featuredProject)

  const expandProject = useCallback(
    () => onExpand(featuredProject),
    [onExpand, featuredProject],
  )

  const labelText =
    transaction !== undefined ? (
      <>
        mint {transaction.nftCount} for{' '}
        <span className="uppercase">
          {formatTokenAmount({
            amount: transaction.ethValue,
          })}
        </span>
      </>
    ) : null

  const { backgroundColor, textColor } = collection.settings.mintButton ?? {}

  return (
    <div
      className={classnames('flex flex-1 flex-col gap-y-4 relative', className)}
    >
      <div className="flex flex-col">
        {oneSampleNFT !== undefined && (
          <button className="w-full h-auto" onClick={expandProject}>
            <Media
              nft={oneSampleNFT}
              className="w-full h-auto rounded-md fade-out-bottom cursor-pointer"
              loading="eager"
            />
          </button>
        )}
        <div className="absolute bottom-3 md:bottom-8 left-0 w-full py-2 px-3 mb-6 hidden md:block">
          <div className="flex flex-col items-start gap-y-2">
            <button
              className="font-bold text-2xl text-left hover:underline line-clamp-1 md:line-clamp-2 normal-case"
              onClick={expandProject}
            >
              {collection.name}
            </button>
            <ProjectSubtitleText
              collection={collection}
              className="text-sm hidden md:block"
              includeCountdown={false}
            />
          </div>
          {oneSampleNFT !== undefined && (
            <span className="line-clamp-1 text-xs">
              {formatTokenAmount({
                amount: oneSampleNFT.ethPrice,
              })}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex md:hidden flex-col w-full">
          <Link
            href={getContractPath(collection.contract)}
            className="font-bold hover:underline line-clamp-1 md:line-clamp-2 break-all normal-case"
          >
            {collection.name}
          </Link>
          {oneSampleNFT !== undefined && (
            <div className="text-xs line-clamp-1">
              {formatTokenAmount({
                amount: oneSampleNFT.ethPrice,
              })}
            </div>
          )}
        </div>
        {isMintedOut ? (
          <>
            <Button
              variant="outline"
              label="Minted out"
              className="w-full hidden md:flex justify-center"
              onClick={expandProject}
            />
            <button
              onClick={expandProject}
              className="md:hidden font-bold underline text-left"
            >
              Minted out
            </button>
          </>
        ) : (
          labelText !== null && (
            <>
              <Button
                variant="primary"
                className="hidden md:flex w-full md:w-full text-sm tracking-tight justify-center"
                style={{
                  backgroundColor: backgroundColor,
                  color: textColor,
                }}
                labelClassName="truncate"
                onClick={() => onExpand(featuredProject)}
                label={labelText}
              />

              {/* mobile button no styling */}
              <Button
                variant="primary"
                className="md:hidden flex w-full md:w-full text-sm tracking-tight !underline !bg-transparent pl-0 py-0"
                labelClassName="truncate"
                onClick={() => onExpand(featuredProject)}
                label="Mint now"
              />
            </>
          )
        )}
      </div>
    </div>
  )
}
function FeaturedProjectMultiple({ featuredProjects, onExpand }: Props) {
  return (
    <div className="featured-projects flex flex-col gap-y-3 md:gap-y-6">
      <div>
        <FeaturedProjectChip className="mb:0 md:mb-0" multiple />
      </div>
      <div className="flex justify-between gap-x-4 md:gap-y-4 py-2">
        {featuredProjects.map((featuredProject, i) => (
          <FeaturedProjectMultipleEntry
            featuredProject={featuredProject}
            key={featuredProject.collection.contract + i}
            className={i > 2 ? 'hidden lg:flex' : undefined}
            onExpand={onExpand}
          />
        ))}
      </div>
    </div>
  )
}

export default React.memo(FeaturedProjectView)
