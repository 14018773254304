import React from 'react'
import { Modal } from 'components/Modal'
import { lazy } from 'utils/lazy'
import type { Props as ContentProps } from './ModalContent'

const ModalContent = lazy({
  loader: () => import('./ModalContent'),
  Fallback: () => null,
})

type Props = {
  trigger: JSX.Element
} & ContentProps

function AddressListModal({ trigger, ...contentProps }: Props) {
  return (
    <Modal trigger={trigger}>
      <ModalContent {...contentProps} />
    </Modal>
  )
}

export default React.memo(AddressListModal)
