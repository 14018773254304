import { useCallback } from 'react'
import classNames from 'utils/classnames'
import Badge from './Badge'
import { Button, ButtonLink } from './Button'

type PickerProps<T extends string> = {
  optionKey: T
  label: string
  isSelected: boolean
  badge?: number
  href?: string
  onSelectOption?: (key: T) => void
}

function Picker<T extends string>({
  optionKey,
  isSelected,
  onSelectOption,
  badge,
  label,
  href,
}: PickerProps<T>) {
  const handleClick = useCallback(
    () => onSelectOption?.(optionKey),
    [onSelectOption, optionKey],
  )

  const variant = isSelected ? 'filterSelected' : 'filterDefault'

  if (href !== undefined) {
    return (
      <ButtonLink
        shallow
        variant={variant}
        label={label}
        href={href}
        onClick={handleClick}
        icon={
          badge !== undefined ? <Badge label={badge.toString()} /> : undefined
        }
        iconPosition="end"
      />
    )
  }

  return (
    <Button
      variant={variant}
      label={label}
      icon={
        badge !== undefined ? <Badge label={badge.toString()} /> : undefined
      }
      iconPosition="end"
      onClick={handleClick}
    />
  )
}

export type OptionSelectorOption<T extends string> = {
  key: T
  label: string
  badge?: number
  href?: string
}

export type OptionSelectorProps<T extends string> = {
  options: OptionSelectorOption<T>[]
  selected: T
  onSelectOption?: (key: T) => void
  className?: string
}

function OptionSelector<T extends string>({
  options,
  selected,
  onSelectOption,
  className,
}: OptionSelectorProps<T>) {
  return (
    <div className={classNames('flex flex-row gap-2', className)}>
      {options.map(({ key, label, href, badge }) => (
        <Picker
          key={key}
          optionKey={key}
          label={label}
          isSelected={key === selected}
          href={href}
          onSelectOption={onSelectOption}
          badge={badge}
        />
      ))}
    </div>
  )
}

export default OptionSelector
