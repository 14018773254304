import { useMemo } from 'react'
import classnames from 'utils/classnames'
import { useAccount } from 'utils/useAccount'
import {
  AddressHash,
  Contract,
  contractMintedEndpoint,
  makeContract,
  useAPIResponse,
} from './api'
import { base, defaultChain, goerli, isTestnet } from './chains'

const isTestnetFundrop = isTestnet(defaultChain.id)

export const fundropAddress: AddressHash =
  '0x0000000000664ceffed39244a8312bD895470803' as AddressHash

export const fundropContract: Contract = makeContract({
  address: fundropAddress,
})

export const funFinaleCollectionName = '!fundrop finale ID'
export const funFinaleChain = isTestnetFundrop ? goerli : base
export const funFinaleChainId = funFinaleChain.id
export const funFinaleAddress: AddressHash =
  '0x000000000007EE3671914a485384E0f394D5B75c' as AddressHash

export const funFinaleContract = makeContract({
  address: funFinaleAddress,
  chainId: funFinaleChainId,
})

export const funFinaleRendererAddress =
  '0xCEFB9646E1E43c2D7d1B267DF427D4ED3034e9d2' as AddressHash

export const fundFinaleRendererContract = makeContract({
  address: funFinaleRendererAddress,
  chainId: funFinaleChainId,
})

export const teamsAddress: AddressHash =
  '0x0000000050AB90ba19f160545F9D275b98671C10' as AddressHash

export const teamsContract: Contract = makeContract({
  address: teamsAddress,
})

export const useHasMintedFundrop = (revalidate = true) => {
  const { address } = useAccount()
  const { data: mintedResponse } = useAPIResponse<{ count: string }>(
    contractMintedEndpoint({
      contract: fundropContract,
      minterAddress: address,
    }),
    undefined,
    {
      skipFetching: address === undefined,
      revalidateIfStale: revalidate,
      revalidateOnFocus: revalidate,
      revalidateOnReconnect: revalidate,
    },
  )

  return useMemo(() => {
    return mintedResponse !== undefined
      ? mintedResponse.count !== '0'
      : undefined
  }, [mintedResponse])
}

export const fundropButtonColorClasses = classnames(
  'bg-accent-fun-purple dark:bg-accent-fun-purple hover:bg-accent-fun-purple/80 dark:hover:bg-accent-fun-purple/80 hover:text-white dark:hover:text-white',
)

export const twoDays = 2 * 24 * 60 * 60 * 1000
