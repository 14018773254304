import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Contract, makeContract } from 'utils/api'
import { getChainByAlias, getContractPath } from 'utils/chains'
import { specialDestination } from 'utils/special-contracts'
import { isAddressHash } from 'utils/web3/helpers'

export const useProjectModalState = (startPath: string) => {
  const [selectedContract, selectedContractSet] = useState<
    Contract | undefined
  >(undefined)

  const [projectModalOpen, projectModalOpenSet] = useState(false)

  const { push } = useRouter()

  const handleExpand = useCallback(
    (contract: Contract) => {
      const dest = specialDestination(contract)
      if (dest !== undefined) {
        push(dest)
        return
      }

      selectedContractSet(contract)
      projectModalOpenSet(true)

      // push :chain/:selectedContract to the history without triggering a page reload
      window.history.pushState({}, '', getContractPath(contract))
    },
    [push],
  )

  const handleModalClose = useCallback(() => {
    projectModalOpenSet(false)

    // since we can't pop without a page reload, we'll replace the current history entry
    // with the original url
    window.history.replaceState({}, '', startPath)
  }, [startPath])

  useEffect(() => {
    const handlePopState = () => {
      if (window.location.pathname === startPath) {
        projectModalOpenSet(false)
        return
      }

      const [chainAlias, contractAddress] = window.location.pathname
        .slice(1)
        .split('/')
      if (!chainAlias || !contractAddress) {
        return
      }

      const chain = getChainByAlias(chainAlias)

      if (!chain || !isAddressHash(contractAddress)) {
        return
      }
      // extract the contract chain and address from the pathname and open the modal
      const contract = makeContract({
        address: contractAddress,
        chainId: chain.id,
      })
      selectedContractSet(contract)
      projectModalOpenSet(true)
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [startPath])

  return {
    projectModalOpen,
    selectedContract,
    handleExpand,
    handleModalClose,
  }
}
