import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { supportedChains } from 'utils/chains'
import { ChainIcon } from '../ChainIcon'
import { StringDropdownSelect } from '../DropdownSelect'

export const ChainDropdownSelect = () => {
  const router = useRouter()
  const { chain: queryChain } = router.query

  const initialChain =
    router.isReady && isChainValue(queryChain) ? queryChain : options[0].value
  const [dropdownOption, dropdownOptionSet] = useState(initialChain)

  useEffect(() => {
    const chain = queryChain
    if (chain === undefined) {
      dropdownOptionSet('all-chains')
    } else if (isChainValue(chain) && chain !== dropdownOption) {
      dropdownOptionSet(chain)
    }
  }, [dropdownOption, queryChain])

  const handleChange = useCallback(
    (value: string) => {
      const query = { ...router.query }
      if (value === 'all-chains') {
        delete query.chain
      } else {
        query.chain = value
      }
      router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    [router],
  )

  if (dropdownOption === undefined) {
    return null
  }

  return (
    <StringDropdownSelect
      options={options}
      value={dropdownOption}
      onChange={handleChange}
      labelClassName="text-lg"
      contentClassName="min-w-[150px]" // should be set to width of longest option
      optionClassName="px-2.5 text-base"
    />
  )
}

const options = [{ label: <>All chains</>, value: 'all-chains' }].concat([
  ...supportedChains.map((chain) => ({
    label: <span className="normal-case">{chain.name}</span>,
    ariaLabel: chain.name,
    value: chain.alias,
    icon: () => <ChainIcon chainId={chain.id} className="h-4 w-4 pt-0.5" />,
  })),
])

type ChainValue = typeof options[number]['value']

const isChainValue = (value: unknown): value is ChainValue =>
  options.some((option) => option.value === value)
