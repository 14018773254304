import { Contract, isContractEqual } from './api'
import { funFinaleContract, fundropContract } from './fundrop'

type ContractData = {
  redirect: string
}

const specialContracts = new Map<Contract, ContractData>([
  [
    fundropContract,
    {
      redirect: '/fundrop',
    },
  ],
  [
    funFinaleContract,
    {
      redirect: '/fundrop',
    },
  ],
])

const specialContractExtra = (contract: Contract): ContractData | undefined => {
  for (const [special] of specialContracts) {
    if (isContractEqual(special, contract)) {
      return specialContracts.get(special)
    }
  }
  return undefined
}

export const specialDestination = (contract: Contract) =>
  specialContractExtra(contract)?.redirect

export const isSpecialContract = (contract: Contract): boolean =>
  specialContractExtra(contract) !== undefined
