import { SVGAttributes } from 'react'

export default function ChevronRight(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.667 13.4c.736 0 1.333-.627 1.333-1.4 0-.773-.597-1.4-1.333-1.4h-1.334V9.48c0-.928-.716-1.68-1.6-1.68h-1.066V6.4c0-.773-.597-1.4-1.334-1.4C8.597 5 8 5.627 8 6.4c0 .773.597 1.4 1.333 1.4h1.334v1.12c0 .928.716 1.68 1.6 1.68h1.066v2.8h-1.066c-.884 0-1.6.752-1.6 1.68v1.12H9.333C8.597 16.2 8 16.827 8 17.6c0 .773.597 1.4 1.333 1.4.737 0 1.334-.627 1.334-1.4v-1.4h1.066c.884 0 1.6-.752 1.6-1.68V13.4h1.334z"
      ></path>
    </svg>
  )
}
