import { ButtonDiv } from 'components/Button'
import ChevronDown from 'components/icons/ChevronDown'
import type { Props } from '.'
import { defaultVariant, variants } from './variants'

export default function Trigger<T extends string>({
  variant = defaultVariant,
  options,
  value,
  labelClassName,
  disabled,
}: Props<T>) {
  const currentOption = options.find((o) => o.value === value)
  return (
    <ButtonDiv
      className={variants[variant].trigger}
      label={currentOption?.label ?? ''}
      labelClassName={labelClassName}
      secondaryLabel={currentOption?.secondaryLabel}
      icon={<ChevronDown className="w-[1em] h-[1em]" />}
      iconPosition="end"
      variant={variant}
      disabled={disabled}
    />
  )
}
