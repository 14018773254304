import { ReactNode } from 'react'
import { lazy } from 'utils/lazy'
import Trigger from './Trigger'
import { variants } from './variants'

export type Props<T extends string> = {
  variant?: keyof typeof variants
  id?: string
  options: ReadonlyArray<{
    label: NonNullable<ReactNode>
    secondaryLabel?: ReactNode
    ariaLabel?: string
    value: T
    icon?: () => JSX.Element
  }>
  value: T
  onChange?: (value: T) => void
  open?: boolean
  dropdownWidth?: number
  contentClassName?: string
  optionClassName?: string
  labelClassName?: string
  disabled?: boolean
}

// Typescript doesn't like exporting a lazy generic component so we export
// a method to create a lazy component instead.
// For convenience, we'll also export a string version of the component.
export const StringDropdownSelect = lazy<Props<string>>({
  loader: async () => await import('./Component'),
  Fallback: ({ props }) => <Trigger {...props} />,
})

export const makeDropdownSelect = <T extends string>() =>
  lazy<Props<T>>({
    loader: async () => await import('./Component'),
    Fallback: ({ props }) => <Trigger {...props} />,
  })
