import React from 'react'
import Link from 'next/link'
import ProjectSubtitleText from 'components/ProjectSubtitleText'
import { Collection } from 'utils/api/types'
import { getContractPath } from 'utils/chains'
import FeaturedProjectChip from './FeaturedProjectChip'

type Props = {
  collection: Collection
}

function FeaturedProjectDetails({ collection }: Props) {
  return (
    <>
      <FeaturedProjectChip />
      <div className="flex flex-col items-start gap-y-2">
        <Link
          href={getContractPath(collection.contract)}
          className="text-3xl font-bold hover:underline line-clamp-2 normal-case"
        >
          {collection.name}
        </Link>
        <ProjectSubtitleText collection={collection} className="text-lg" />
      </div>
    </>
  )
}

export default FeaturedProjectDetails
