import { SVGProps } from 'react'

export default function MintRewards(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 2.732C1 2.328 1.329 2 1.735 2h18.122c.406 0 .735.328.735.732V3.83a.611.611 0 01-1.224 0 .611.611 0 00-.613-.61h-1.102a.733.733 0 00-.734.732v1.099c0 .337.274.61.612.61a.611.611 0 110 1.22H2.959a.733.733 0 00-.735.732v11.958c0 .405.33.732.735.732h15.674a.733.733 0 00.735-.732v-5.979a.611.611 0 011.224 0v7.2a.733.733 0 01-.735.732H1.735A.733.733 0 011 20.79V2.732zm1.224 1.22c0-.404.33-.732.735-.732h12c.406 0 .735.328.735.732v.977a.733.733 0 01-.735.732h-12a.733.733 0 01-.735-.732v-.977zm18.379 2.212c-.2-.576-1.016-.576-1.216 0l-.402 1.16a.642.642 0 01-.398.396l-1.164.401a.64.64 0 000 1.211l1.165.4c.186.065.333.211.397.397l.402 1.16c.2.576 1.016.576 1.216 0l.402-1.16a.642.642 0 01.398-.396l1.164-.401a.64.64 0 000-1.211l-1.164-.4a.641.641 0 01-.398-.397l-.402-1.16z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M15.41 10.8v.362c0 .248.2.449.448.449h.144c.247 0 .447.2.447.448v5.546c0 .248-.2.449-.447.449H13.82a.448.448 0 01-.447-.449v-5.024c0-.248-.2-.45-.448-.45h-.549a.448.448 0 00-.447.45v5.024c0 .248-.2.449-.448.449H9.314a.448.448 0 01-.447-.449v-5.024c0-.248-.2-.45-.448-.45h-.563a.448.448 0 00-.448.45v5.024c0 .248-.2.449-.447.449H4.793a.448.448 0 01-.448-.449v-6.806c0-.247.2-.448.448-.448h10.17a.45.45 0 01.447.45z"
      ></path>
    </svg>
  )
}
