import { SVGProps } from 'react'

export default function StarFilled(props: Partial<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.375 3c-.345 0-.625.265-.625.593 0 .327-.28.592-.625.592s-.625.265-.625.593v1.185c0 .327-.28.593-.625.593s-.625.265-.625.592c0 .327-.28.593-.625.593h-5c-.345 0-.625.265-.625.592 0 .328.28.593.625.593s.625.265.625.593c0 .327.28.592.625.592s.625.266.625.593c0 .327.28.592.625.592s.625.266.625.593v1.185c0 .327-.28.592-.625.592s-.625.266-.625.593v1.185c0 .327-.28.593-.625.593s-.625.265-.625.592v1.185c0 .328-.28.593-.625.593S2 18.672 2 19v1.067c0 .392.336.71.75.71h2.375c.345 0 .625-.265.625-.592 0-.327.28-.592.625-.592h1.25c.345 0 .625-.266.625-.593 0-.327.28-.593.625-.593h1.25c.345 0 .625-.265.625-.592 0-.327.28-.593.625-.593h1.25c.345 0 .625.266.625.593 0 .327.28.592.625.592h1.25c.345 0 .625.266.625.593 0 .327.28.593.625.593h1.25c.345 0 .625.265.625.592 0 .328.28.593.625.593h2.375c.414 0 .75-.319.75-.711V19c0-.328-.28-.593-.625-.593s-.625-.265-.625-.593V16.63c0-.327-.28-.592-.625-.592s-.625-.265-.625-.593V14.26c0-.327-.28-.593-.625-.593s-.625-.265-.625-.592v-1.185c0-.327.28-.592.625-.592s.625-.266.625-.593c0-.327.28-.593.625-.593s.625-.265.625-.592c0-.328.28-.593.625-.593S22 8.661 22 8.333c0-.327-.28-.592-.625-.592h-5c-.345 0-.625-.266-.625-.593 0-.327-.28-.592-.625-.592s-.625-.266-.625-.593V4.778c0-.327-.28-.593-.625-.593s-.625-.265-.625-.592c0-.328-.28-.593-.625-.593h-1.25z"
      ></path>
    </svg>
  )
}
